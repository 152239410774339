@import "./variables.scss";

@media only screen and (max-width: 832px), (max-device-width: 416px) {
  .loader p.sub-title {
    font-size: 4vw;
  }

  .content-container {
    width: 100%;
    overflow-x: hidden;
  }

  .header-logo {
    width: 24vw;
    padding-top: 12vw;
    margin-bottom: 18vw;
  }

  .large-title {
    font-size: 9.2vw;
    line-height: 1.2;
    width: 80%;
  }

  .video-container {
    width: 150vw;
    margin-left: -25vw;
    padding-bottom: 90vw;
    margin-top: -10vw;
  }

  video {
    width: 250vw;
    transform: translate3d(-25%, -25%, 0);

  }

  .medium-title {
    width: 85%;
    font-size: 7vw;
    line-height: 1.4;

    .sub-title {
      font-size: 4vw;
      margin-bottom: 1vw;
    }

    div {
      margin-top: 4vw;
      margin-bottom: 16vw;
    }
  }

  .sub-title {
    width: 85%;
    margin: auto;
    font-size: 3vw;
    letter-spacing: 0.75vw;
    line-height: 2;
  }

  .accent-copy {
    font-size: 4vw;
    line-height: 1.6;
  }

  .body-copy {
    font-size: 4.8vw;
    line-height: 1.5;
  }

  .accent-copy {
    font-size: 5.6vw;
    line-height: 1.6;
  }

  .mission {
    margin-top: 12vw;
    margin-bottom: 50vw;
    width: 80%;
  }

  .we-are-thoughtful {
    margin-bottom: 40vw;
  }

  .where-we-invest {
    margin-bottom: 8vw;
  }

  .card-gallery {
    flex-direction: column;

    width: 80%;

    margin-bottom: 32vw;

    .card {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      flex: none;

      &:first-of-type {
        margin-left: 0px;
      }

      &:not(:first-of-type) {
        margin-left: 0px;
      }

      &:hover {
        transform: translate3d(0,0,0);
      }

      .card-title {
        font-size: 5.5vw;
        min-height: 7vw;
        overflow: visible;
        padding-bottom: 0;
      }

      .card-bottom {
        padding: 7vw 7vw 24vw 7vw;
      }

      .card-content {
        // padding-top: 2vw;
        font-size: 4vw;
        line-height: 1.6;

        ul {
          padding-inline-start: 3.5vw;
        }

      }

      button {
        font-size: 3.5vw;

        .button-text-wrap {
          margin: 0 7vw 7vw 0;
        }
      }
    }
  }

  .how-table {
    width: 85%;
    margin-bottom: 24vw;

    .how-table-row {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 5vw;
      border-top: 1px solid $brand-color-light;

      .tablet-column {
        flex-direction: column;
      }

      .number {
        font-size: 5vw;
        line-height: 1;
        margin-bottom: 5vw;
        font-weight: 800;
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 3vw;
          margin-bottom: 2px;
        }
      }

      .question {
        font-size: 8vw;
        margin: 2vw 0 2vw 0;
        line-height: 1.25;
        max-width: none;
      }

      .answer {
        margin-left: 0;
        max-width: none;
        font-size: 5vw;
      }
    }
  }

  .where-weve-invested {
    margin-bottom: 24vw;
  }

  .cutout-gallery {
    width: 80%;
    margin-bottom: 40vw;

    .cutout-inner {
      width: 240vw;
      flex-direction: row;
    }

    .mobile-controls {
      display: flex;

      z-index: 10;

      width: 80%;
      margin: 4vw auto 0 auto;

      justify-content: space-around;
      align-items: center;

      font-size: 5vw;
      letter-spacing: 1vw;
      font-weight: 600;

      .previous-button,
      .next-button {
        outline: none;
        border: none;
        font-size: 16vw;
        cursor: pointer;
        background: transparent;
        border: none;
        -webkit-appearance: none;
        color: $accent-color-deepblue;
        padding: 1vw 1vw 1vw 1vw;

        &:active {
          outline: none;
        }
      }

      .previous-button {
        margin-right: 16px;
      }

      .next-button {
        margin-left: 16px;
      }
    }

    .row {
      flex-direction: column;
      width: 80vw;

      &:nth-of-type(2) {
        transform: translate(100vw, 0);
        margin-left: -80vw;
      }

      &:nth-of-type(3) {
        transform: translate(-100vw, 0);
        margin-left: -80vw;
      }

      &:not(:first-of-type) {
        margin-top: 0;
      }

      img {
        flex: none;
        width: 80%;
      }

      .text-wrap {
        .cutout-header {
          font-size: 7vw;
          line-height: 1.3;
          margin-top: 8vw;
          margin-bottom: 6vw;
        }
      }
    }

    .water,
    .sunset,
    .farmland {
      margin-right: 0;
      margin-left: 0;
      width: 90%;
    }
  }

  .our-team {
    max-width: 60%;
    margin-bottom: 40vw;
  }

  .team-container {
    width: 78%;
    margin-bottom: 24vw;

    .team-member {
      flex-direction: column;
      margin-top: 6vw;

      .headshot {
        margin: auto;
        width: 100%;

        img {
          width: 100%;
          margin-bottom: 6vw;
        }

        .links {
          justify-content: center;
          margin-bottom: 2vw;
          a {
            padding-left: 2vw;
            padding-right: 2vw;
            font-size: 4.5vw;
            letter-spacing: 1vw;
          }
        }

        .name {
          font-size: 7vw;
        }
      }

      .about {
        margin: 0;

        .body-copy {
          font-size: 4.8vw;
          margin-bottom: 0;
        }

        .about-bottom {
          flex-direction: column;
          margin-top: 4vw;

          .sub-title {
            font-size: 5vw;
            font-weight: 600;
          }

          .detail,
          .experience {
            font-size: 4.5vw;
            margin-bottom: 5vw;
            margin-left: 0;
          }
        }

        .sub-title {
          width: 100%;
        }
      }
    }
  }

  .bottom-links {
    flex-direction: column;
    width: 85%;
    margin-bottom: 24vw;

    div.link-wrap {
      max-width: 100%;
      width: 100%;

      &:first-of-type {
        margin-bottom: 8vw;
      }
    }

    .link-text {
      font-size: 7vw;
      max-width: 70%;
      margin-left: 8vw;
      margin-top: 10vw;
      // margin-top: 50%;
    }

    .link-arrow {
      font-size: 8vw;
      max-width: 48px;
    }
  }

  #mce-EMAIL {
    display: block;
  }
  
  .clear {
    margin-top: 6vw;
  }

  .email-list {
    .large-title {
      margin-bottom: 8vw;
    }

    .sub-title {
      line-height: 0;
    }

    margin-bottom: 32vw;

    input {
      max-width: 80%;
      width: 80%;
      font-size: 4vw;
    }

    button {
      margin-top: 6vw;
      width: 40%;
      font-size: 4vw;
    }
  }

  footer {
    height: auto;
    padding: 20vw 16px;

    .footer-inner {
      flex-direction: column;
      width: 85%;

      i.body-copy {
        font-size: 5vw;
      }

      .footer-logo {
        width: 40%;
        margin: auto;
      }

      .footer-links {
        flex-direction: column;

        .office {
          width: 100%;
        }

        div {
          display: block;
          margin-bottom: 40px;
        }

        .sub-title {
          font-size: 6vw;
          margin-left: 0;
          margin-bottom: 3vw;
        }

        .follow-us {
          width: 100%;
          margin-right: 0;
          justify-content: space-around;
        }

        .link-wrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        a {
          font-size: 5vw;
          letter-spacing: 1vw;
        }
      }
    }
  }
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-SemiBoldItalic.5e68bc3a.woff2') format('woff2'),
        url('SourceSansPro-SemiBoldItalic.6c6c3ab7.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Italic.a956317c.woff2') format('woff2'),
        url('SourceSansPro-Italic.afaf07aa.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Regular.5e671594.woff2') format('woff2'),
        url('SourceSansPro-Regular.86bedaf9.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-SemiBold.c4f6999d.woff2') format('woff2'),
        url('SourceSansPro-SemiBold.e08dab2a.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-LightItalic.cd4cec3f.woff2') format('woff2'),
        url('SourceSansPro-LightItalic.77fdd075.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Light.aa161e8a.woff2') format('woff2'),
        url('SourceSansPro-Light.c933734e.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('SourceSerifPro-SemiBoldIt.110b2f46.woff2') format('woff2'),
        url('SourceSerifPro-SemiBoldIt.7e6d8bf8.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('SourceSerifPro-SemiBold.8891932e.woff2') format('woff2'),
        url('SourceSerifPro-SemiBold.7654afd9.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('SourceSerifPro-It.9f840892.woff2') format('woff2'),
        url('SourceSerifPro-It.dfd77ed9.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('SourceSerifPro-Regular.4c86ba22.woff2') format('woff2'),
        url('SourceSerifPro-Regular.b2d2fab5.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('SourceSerifPro-LightIt.fc13659c.woff2') format('woff2'),
        url('SourceSerifPro-LightIt.5434756b.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('SourceSerifPro-Light.3acbd3bd.woff2') format('woff2'),
        url('SourceSerifPro-Light.98db5b52.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
body,
html {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.gold {
  color: #c69f31;
}

.coral {
  color: #c6764c;
}

.marine {
  color: #386163;
}

.deepblue {
  color: #414d48;
}

.page-gradient {
  background: #f8f8f8;
  background: -webkit-linear-gradient(top, #f8f8f8, #e3e3e3);
  background: linear-gradient(to bottom, #f8f8f8, #e3e3e3);
}

.footer-gradient {
  background: #35414c;
  background: -webkit-linear-gradient(top, #35414c, #232d33);
  background: linear-gradient(to bottom, #35414c, #232d33);
}

body {
  overflow-x: hidden;
}

.clipping-paths {
  width: 0;
  height: 0;
  position: absolute;
}

.loader {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  z-index: 100;
}
.loader img,
.loader p {
  text-align: center;
}
.loader .sub-title {
  color: #1b2428;
  font-size: 1.5vw;
  line-height: 1;
  margin: 0 auto;
  letter-spacing: 1.2px;
}
.loader span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 10px 3px 0;
  background: #1b2428;
  border-radius: 50%;
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  animation: wave 2s infinite ease-in-out;
}
@keyframes wave {
  0%, 60%, 100% {
    background: #303f46;
    transform: translateY(0);
    -moz-transform: translateY(0);
  }
  20% {
    background: #445b65;
    transform: translateY(20px);
  }
  40% {
    background: #70909f;
    transform: translateY(-10px);
  }
}
.loader span:nth-child(1) {
  animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.loader span:nth-child(2) {
  animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}
.loader span:nth-child(3) {
  animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.loader span:nth-child(4) {
  animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.loader span:nth-child(5) {
  animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

p {
  padding-bottom: 1vw;
}

.main {
  display: flex;
  flex-direction: column;
}

.content-container {
  max-width: 1850px;
  margin: 0 auto;
}

/* unvisited link */
a:link {
  color: #1b71c9;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 45px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.25vw;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #4B84D1;
}

/* mouse over link */
a:hover {
  text-decoration: underline;
}

/* selected link */
a:active {
  text-decoration: underline;
  color: #0B539D;
}

a:focus {
  text-decoration: underline;
  outline: none;
}

.header-logo {
  width: 7.25vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4vw;
  margin-bottom: 4.5vw;
}

.we-act {
  position: relative;
  z-index: 10;
  text-align: center;
}

.video-container {
  margin: 0 auto;
  height: 0;
  padding-bottom: 60vw;
  width: 100vw;
  margin-top: -5vw;
  clip-path: url(#clip-hero-video);
  margin-bottom: 4.4vw;
  overflow: hidden;
}

video {
  min-width: 0px;
  width: 150vw;
  overflow: hidden;
  transform: translate3d(-25%, -25%, 0);
}

.video-insert::-webkit-media-controls {
  display: none !important;
}

.mission {
  width: 41%;
  margin: auto;
  text-align: center;
  margin-bottom: 16vw;
}

.center-copy {
  text-align: center;
}

.descriptor-copy {
  color: #35414c;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 45px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
}

.sub-title {
  color: #707e8e;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 3.5;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.25vw;
}

.link-copy {
  color: #1b71c9;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 4px;
}

.body-copy {
  color: #35414c;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 2vw;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.24px;
}

.accent-copy {
  color: #35414c;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.6vw;
  line-height: 1.6;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.3px;
}

.medium-title {
  width: 74%;
  margin: 0 auto;
  color: #4e5860;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 2.25vw;
  line-height: 1.25;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.42px;
}

.number {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.25vw;
  font-family: "Source Serif Pro", serif;
  letter-spacing: 0px;
}

.question {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 2.25vw;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.44px;
}

.large-title {
  width: 65.65%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  color: #4e5860;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 4vw;
  line-height: 1.3;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.5px;
  text-align: center;
}

.we-are-thoughtful {
  margin-bottom: 16vw;
}

.where-we-invest {
  margin-bottom: 5vw;
}

.card-gallery {
  width: 79.79%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15vw;
}
.card-gallery .card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  transform: translate3d(0, 0px, 0);
  border-radius: 16px;
  box-shadow: 0px 0px 10px #00000029;
  overflow: hidden;
  background-color: #f8f8f8;
  transition: transform 0.4s;
  transition-delay: 0.1s;
}
.card-gallery .card:hover, .card-gallery .card:focus-within {
  transform: translate3d(0, -10px, 0);
}
.card-gallery .card:focus-within {
  box-shadow: 0px 0px 10px #00000055;
}
.card-gallery .card:not(:first-of-type) {
  margin-left: 20px;
}
.card-gallery .card .card-img-wrap {
  height: 0;
}
.card-gallery .card .card-img-wrap:after {
  content: "";
  padding-bottom: 80%;
}
.card-gallery .card .card-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 81.6%;
  padding: 2vw 1vw 4vw 1.5vw;
  background-color: #f8f8f8;
  color: #414d48;
}
.card-gallery .card .card-title {
  font-family: "Source Serif Pro", serif;
  font-size: 1.5vw;
  z-index: 10;
}
.card-gallery .card .card-content {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1vw;
  opacity: 0;
  padding-top: 0.5vw;
  height: 0px;
}
.card-gallery .card .card-content ul {
  padding-inline-start: 1.25vw;
}
.card-gallery .card button {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  z-index: 11;
  background: transparent;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  color: #1b71c9;
  font-weight: 600;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  justify-content: flex-end;
  align-items: flex-end;
}
.card-gallery .card button:focus {
  outline: none;
}
.card-gallery .card button:active {
  color: #4a97e7;
  appearance: none;
  outline: none;
}
.card-gallery .card button:visited {
  outline: none;
}
.card-gallery .card button .button-text-wrap {
  margin: 0 16px 16px 0;
  overflow: hidden;
}
.card-gallery .card .bottom-bar {
  position: absolute;
  height: 50px;
  bottom: 0;
  width: 100%;
  background-color: #f8f8f8;
}

.how-we-invest {
  margin-bottom: 5vw;
}

.how-table {
  display: flex;
  flex-direction: column;
  width: 79.5%;
  margin: auto;
  margin-bottom: 8vw;
}
.how-table .how-table-row {
  display: flex;
  align-items: top;
  padding-top: 25px;
  margin-bottom: 49px;
  border-top: 1px solid #dadcdf;
}
.how-table .how-table-row .tablet-column {
  display: flex;
  flex-direction: row;
  flex: 4;
}
.how-table .how-table-row .number {
  flex: 1;
  font-weight: 600;
}
.how-table .how-table-row .number img {
  width: 16px;
  margin-right: 8px;
  margin-bottom: -2px;
}
.how-table .how-table-row .question {
  flex: 2;
  justify-self: left;
  margin-right: 6vw;
  max-width: 420px;
}
.how-table .how-table-row .answer {
  flex: 3;
  margin-left: 6vw;
}

.where-weve-invested {
  margin-bottom: 6vw;
  width: 55%;
}

.cutout-gallery {
  width: 63.5%;
  margin: 0 auto;
  margin-bottom: 16vw;
}
.cutout-gallery .cutout-inner {
  display: flex;
  flex-direction: column;
}
.cutout-gallery .mobile-controls {
  display: none;
  color: #414d48;
  font-family: "Source Sans Pro", sans-serif;
}
.cutout-gallery .mobile-controls button.previous-button img {
  transform: scaleX(-1);
}
.cutout-gallery .mobile-controls button {
  padding: 1vw 0.5vw 1vw 0.5vw;
  line-height: 0.55;
  outline: 0;
  display: flex;
}
.cutout-gallery .mobile-controls button:focus {
  outline: 2px dotted #707e8e;
}
.cutout-gallery .mobile-controls button:active {
  outline: 0;
}
.cutout-gallery .sunset {
  width: 50%;
  clip-path: url(#clip-sunset);
  margin-right: 5vw;
  margin-bottom: 6vw;
}
.cutout-gallery .water {
  width: 50%;
  clip-path: url(#clip-water);
  margin-left: 5vw;
  margin-bottom: 6vw;
}
.cutout-gallery .farmland {
  width: 50%;
  clip-path: url(#clip-farmland);
  margin-right: 5vw;
  margin-bottom: 6vw;
}
.cutout-gallery .row {
  display: flex;
  align-items: center;
}
.cutout-gallery .row img {
  flex: 1;
}
.cutout-gallery .row .text-wrap {
  flex: 1;
  margin-top: -6vw;
}
.cutout-gallery .row .text-wrap i {
  font-weight: 600;
}
.cutout-gallery .row .text-wrap .cutout-header {
  font-family: "Source Serif Pro", serif;
  font-size: 1.55vw;
  line-height: 1.5;
  color: #35414c;
  margin-bottom: 1.25vw;
}
.cutout-gallery .flipped {
  flex-direction: row-reverse;
}

.our-team {
  margin-bottom: 16vw;
  width: 50%;
}

.body-copy {
  font-size: 1.25vw;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 0;
}

.team-container {
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  width: 55%;
  margin: 0 auto 10vw auto;
}
.team-container .team-member {
  display: flex;
  align-items: center;
  margin-bottom: 90px;
}
.team-container .team-member:last-of-type {
  margin-bottom: 0;
}
.team-container .team-member .headshot {
  flex: 1.5;
  align-self: flex-start;
}
.team-container .team-member .headshot img {
  display: block;
  width: 77%;
  margin: auto;
  margin-bottom: 1vw;
}
.team-container .team-member .headshot .name {
  text-align: center;
  color: #414d48;
  font-family: "Source Serif Pro", serif;
  font-weight: 600;
  font-size: 1.5vw;
}
.team-container .team-member .headshot .links {
  display: flex;
  justify-content: center;
}
.team-container .team-member .headshot .links a {
  letter-spacing: 3px;
  padding-left: 1.15vw;
  padding-right: 1.15vw;
  margin-top: 1vw;
  line-height: 1.75;
}
.team-container .team-member .headshot .links a:active, .team-container .team-member .headshot .links a:visited {
  outline: none;
}
.team-container .team-member .about {
  flex: 2;
}
.team-container .team-member .about .body-copy {
  margin-bottom: 0vw;
}
.team-container .team-member .about .about-bottom {
  display: flex;
  justify-content: flex-start;
}
.team-container .team-member .about .about-bottom .sub-title {
  line-height: 2;
}
.team-container .team-member .about .about-bottom .detail,
.team-container .team-member .about .about-bottom .experience {
  font-family: "Source Sans Pro", sans-serif;
  color: #414d48;
  font-size: 1vw;
}
.team-container .team-member .about .about-bottom .detail {
  margin-left: 2vw;
}

.bottom-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65.625%;
  margin: auto;
  margin-bottom: 9vw;
}
.bottom-links div {
  z-index: 1;
}
.bottom-links a {
  display: block;
}
.bottom-links div.link-wrap {
  width: calc(50% - 20px);
  border-radius: 16px;
  box-shadow: 0px 0px 10px #0000003e;
}
.bottom-links div.link-wrap:focus-within {
  box-shadow: 0px 0px 10px #00000055;
}
.bottom-links div.link-wrap a {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-family: "Source Serif Pro", serif;
  letter-spacing: 0vw;
  font-size: 2.25vw;
  line-height: 1.5;
  width: 100%;
  outline: none;
}
.bottom-links div.link-wrap a:focus img {
  filter: brightness(40%);
}
.bottom-links div.link-inner {
  position: relative;
  height: 0px;
  padding-top: 66.667%;
}
.bottom-links .link-image-wrap {
  position: absolute;
  height: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  border-radius: 16px;
  z-index: 0;
}
.bottom-links .link-image-wrap img {
  border-radius: 16px;
  transition: filter 0.2s;
  filter: brightness(70%);
}
.bottom-links .link-image-wrap img:hover {
  filter: brightness(40%);
}
.bottom-links .link-text {
  position: absolute;
  top: 0px;
  max-width: 80%;
  margin-left: 3vw;
  margin-top: 4vw;
  color: #f8f8f8;
  pointer-events: none;
}
.bottom-links .link-arrow {
  position: absolute;
  display: flex;
  bottom: 0px;
  right: 0px;
  max-width: 64px;
  margin-right: 32px;
  margin-bottom: 24px;
  color: #f8f8f8;
  font-family: "Source Sans Pro", sans-serif;
  pointer-events: none;
}
.bottom-links .link-text:hover ~ .link-image-wrap img,
.bottom-links .link-arrow:hover ~ .link-image-wrap img {
  filter: brightness(70%);
}

#mc_embed_signup {
  width: 100%;
}

#mce-EMAIL {
  display: block;
}

.clear {
  margin-top: -2.5vw;
}

#mc-embedded-subscribe {
  display: block;
  width: auto;
  padding: 0px 24px;
  inset: none;
  border: none;
  background: #707e8e;
  color: #f8f8f8;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
}
#mc-embedded-subscribe:focus {
  outline: #f8f8f8 auto 1px;
}
#mc-embedded-subscribe:hover {
  background: #35414c;
}
#mc-embedded-subscribe:active {
  background: #1b2428;
  outline: none;
}

.email-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 6vw;
}
.email-list .sub-title {
  line-height: 1;
}
.email-list * {
  margin: 0 auto;
  margin-bottom: 1.25vw;
}
.email-list input {
  width: 25%;
  height: 50px;
  padding: 0;
  outline: 0;
  background: #e3e3e3;
  border: 1px solid #35414c;
  border-radius: 8px;
  text-align: center;
}
.email-list input:focus::-webkit-input-placeholder {
  text-align: center;
  opacity: 0;
}
.email-list input:focus:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  opacity: 0;
}
.email-list input:focus::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  opacity: 0;
}
.email-list input:focus:-ms-input-placeholder {
  text-align: center;
  opacity: 0;
}
.email-list input::-webkit-input-placeholder {
  text-align: center;
}
.email-list input:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}
.email-list input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}
.email-list input:-ms-input-placeholder {
  text-align: center;
}
.email-list button, .email-list input.button {
  width: auto;
  padding: 4px 24px;
  inset: none;
  border: none;
  background: #707e8e;
  color: #f8f8f8;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.email-list button:focus, .email-list input.button:focus {
  outline: #f8f8f8 auto 1px;
}
.email-list button:hover, .email-list input.button:hover {
  background: #35414c;
  outline: none;
}
.email-list button:active, .email-list input.button:active {
  background: #1b2428;
  outline: none;
}

footer {
  display: flex;
  height: 22vw;
  background-color: #1b2428;
}
footer .footer-inner {
  display: flex;
  width: 66.6265%;
  max-width: 1260px;
  justify-content: space-between;
  margin: auto;
}
footer .footer-inner i.body-copy {
  color: #f8f8f8;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1vw;
  font-style: italic;
}
footer .footer-inner .footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 2;
}
footer .footer-inner .footer-links a {
  line-height: 1.3;
  margin-left: -0.5vw;
  margin-top: -0.5vw;
  padding: 0.5vw;
  color: #f8f8f8;
}
footer .footer-inner .footer-links a:visited {
  color: #dadcdf;
  outline: none;
}
footer .footer-inner .footer-links a:active {
  color: #707e8e;
  outline: none;
}
footer .footer-inner .footer-links > div {
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: #f8f8f8;
  margin-bottom: 2vw;
}
footer .footer-inner .footer-links .link-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
footer .footer-inner .footer-links .link-wrap a:nth-of-type(2) {
  margin-left: 1vw;
}
footer .footer-inner .footer-links .link-title {
  color: #95a0a9;
}
footer .footer-inner .footer-links .office {
  min-width: 30%;
}
footer .footer-inner .footer-links .follow-us {
  margin-bottom: 0vw;
  flex: 1;
  margin-right: 6vw;
  min-width: 192px;
}
footer .footer-inner .footer-links .reach-out {
  flex: 1;
  min-width: 100px;
}
footer .footer-inner .footer-links .reach-out a {
  width: max(45%, 3vw);
}
footer .footer-inner .footer-logo {
  width: 80px;
  align-self: flex-start;
}

@media screen and (prefers-reduced-motion: reduce) {
  * {
    /* Very short durations means JavaScript that relies on events still works */
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
.gold {
  color: #c69f31;
}

.coral {
  color: #c6764c;
}

.marine {
  color: #386163;
}

.deepblue {
  color: #414d48;
}

.page-gradient {
  background: #f8f8f8;
  background: -webkit-linear-gradient(top, #f8f8f8, #e3e3e3);
  background: linear-gradient(to bottom, #f8f8f8, #e3e3e3);
}

.footer-gradient {
  background: #35414c;
  background: -webkit-linear-gradient(top, #35414c, #232d33);
  background: linear-gradient(to bottom, #35414c, #232d33);
}

@media only screen and (max-width: 1080px) and (min-width: 833px), (max-device-width: 544px) and (min-device-width: 417px) {
  .loader p.sub-title {
    font-size: 2vw;
  }

  .content-container {
    width: 100%;
    overflow-x: hidden;
  }

  .header-logo {
    width: 12vw;
    padding-top: 6vw;
    margin-bottom: 9vw;
  }

  .large-title {
    font-size: 5.25vw;
    line-height: 1.4;
    width: 85%;
    margin-bottom: 2vw;
  }

  .video-container {
    width: 150vw;
    margin-left: -25vw;
    padding-bottom: 90vw;
    margin-top: -8vw;
    margin-bottom: 12vw;
  }

  video {
    width: 250vw;
  }

  .we-act {
    width: 55%;
    margin-bottom: -3.25vw;
  }

  .medium-title {
    width: 80%;
    font-size: 4vw;
    line-height: 1.3;
  }

  .sub-title {
    width: 85%;
    margin: auto;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 2vw;
    letter-spacing: 3px;
  }

  .accent-copy {
    font-size: 3vw;
    line-height: 1.6;
  }

  .body-copy {
    font-size: min(24px, 4vw);
    line-height: 1.6;
  }

  .mission {
    width: 62%;
    margin-bottom: 30vw;
  }

  .we-are-thoughtful {
    margin-bottom: 30vw;
  }

  .card-gallery {
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
  }
  .card-gallery .card {
    flex: 1 0 calc(50% - 50px);
    margin: 20px 15px 20px 15px;
  }
  .card-gallery .card:hover {
    transform: translate3d(0, 0, 0);
  }
  .card-gallery .card .card-title {
    font-size: 2.75vw;
    min-height: 7vw;
    overflow: visible;
    padding-bottom: 0;
  }
  .card-gallery .card .card-content {
    font-size: 2vw;
    line-height: 1.4;
  }
  .card-gallery .card .card-bottom {
    padding: 4vw 3.5vw 8vw 3.5vw;
  }
  .card-gallery .card button {
    font-size: 1.75vw;
  }
  .card-gallery .card button .button-text-wrap {
    margin: 0 5vw 3vw 0;
  }

  .how-we-invest {
    margin-bottom: 8vw;
    width: 60%;
  }

  .how-table {
    width: 85%;
  }
  .how-table .how-table-row {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 25px;
  }
  .how-table .how-table-row .tablet-column {
    flex-direction: column;
    margin-right: 20px;
  }
  .how-table .how-table-row .number {
    font-size: 2vw;
    line-height: 1;
  }
  .how-table .how-table-row .number img {
    width: 24px;
    margin-bottom: -4px;
  }
  .how-table .how-table-row .question {
    font-size: 4vw;
    margin: 10px 0;
    line-height: 1.5;
  }
  .how-table .how-table-row .answer {
    padding-top: 4.5vw;
    align-self: baseline;
    font-size: 2.15vw;
    margin-left: 0;
  }

  .where-weve-invested {
    margin-bottom: 8vw;
    width: 80%;
  }

  .cutout-gallery {
    width: 70%;
    margin-bottom: 80px;
  }
  .cutout-gallery .cutout-inner {
    width: 210vw;
    flex-direction: row;
  }
  .cutout-gallery .mobile-controls {
    display: flex;
    z-index: 10;
    width: 50%;
    margin: auto;
    justify-content: space-around;
    align-items: center;
    font-size: 2vw;
    letter-spacing: 0.5vw;
    font-weight: 600;
  }
  .cutout-gallery .mobile-controls .previous-button,
.cutout-gallery .mobile-controls .next-button {
    outline: none;
    border: none;
    font-size: 6vw;
    cursor: pointer;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    color: #414d48;
  }
  .cutout-gallery .mobile-controls .previous-button:active,
.cutout-gallery .mobile-controls .next-button:active {
    outline: none;
  }
  .cutout-gallery .mobile-controls .previous-button {
    margin-right: 16px;
  }
  .cutout-gallery .mobile-controls .next-button {
    margin-left: 16px;
  }
  .cutout-gallery .row {
    flex-direction: column;
    width: 70vw;
    margin-bottom: 3vw;
  }
  .cutout-gallery .row:nth-of-type(2) {
    transform: translate(100vw, 0);
    margin-left: -70vw;
  }
  .cutout-gallery .row:nth-of-type(3) {
    transform: translate(-100vw, 0);
    margin-left: -70vw;
  }
  .cutout-gallery .row:not(:first-of-type) {
    margin-top: 0;
  }
  .cutout-gallery .row .text-wrap {
    width: 90%;
  }
  .cutout-gallery .row .text-wrap .body-copy {
    font-size: 2.1vw;
  }
  .cutout-gallery .row .text-wrap .cutout-header {
    font-size: 3vw;
    margin-top: 8vw;
  }
  .cutout-gallery .water,
.cutout-gallery .sunset,
.cutout-gallery .farmland {
    margin-right: 0;
    margin-left: 0;
    width: 80%;
  }

  .our-team {
    margin-top: 25vw;
    margin-bottom: 20vw;
  }

  .team-container {
    width: 66%;
  }
  .team-container .team-member {
    flex-direction: column;
  }
  .team-container .team-member .headshot {
    margin: auto;
    width: 60%;
  }
  .team-container .team-member .headshot img {
    width: 100%;
    margin-bottom: 2vw;
  }
  .team-container .team-member .headshot .links a {
    font-size: 2vw;
    letter-spacing: 0.4vw;
  }
  .team-container .team-member .headshot .name {
    font-size: 3vw;
  }
  .team-container .team-member .about {
    margin: 0;
    width: 95%;
  }
  .team-container .team-member .about .body-copy {
    line-height: 1.5;
  }
  .team-container .team-member .about .about-bottom {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 4vw;
  }
  .team-container .team-member .about .about-bottom .sub-title {
    font-size: 2vw;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1vw;
  }
  .team-container .team-member .about .about-bottom .detail,
.team-container .team-member .about .about-bottom .experience {
    font-size: 2vw;
    margin-bottom: 5vw;
  }
  .team-container .team-member .about .about-bottom .detail {
    margin-left: 8vw;
  }
  .team-container .team-member .about .sub-title {
    width: 100%;
  }

  .bottom-links {
    width: 80%;
    margin-bottom: 15vw;
  }
  .bottom-links .link-text {
    font-size: 2.75vw;
    width: 60%;
  }
  .bottom-links .link-arrow {
    font-size: 6vw;
  }

  .email-list {
    margin-bottom: 10vw;
  }
  .email-list input {
    max-width: 40%;
    width: 40%;
    margin-bottom: 1vw;
  }
  .email-list button {
    margin-top: 1vw;
  }

  footer {
    height: auto;
    padding: 12vw 2vw 6vw 2vw;
  }
  footer .footer-inner {
    width: 75%;
  }
  footer .footer-inner i.body-copy {
    font-size: 2vw;
  }
  footer .footer-inner .footer-logo {
    width: 12vw;
    margin: 10vw 5vw 0vw auto;
  }
  footer .footer-inner .footer-links {
    flex-direction: column;
  }
  footer .footer-inner .footer-links div {
    display: block;
    margin-bottom: 40px;
  }
  footer .footer-inner .footer-links .sub-title {
    font-size: 2vw;
    margin-left: 0;
    margin-bottom: 2.5vw;
    letter-spacing: 0.6vw;
  }
  footer .footer-inner .footer-links .follow-us {
    width: 100%;
    max-width: 350px;
    margin-right: 0;
    justify-content: space-around;
  }
  footer .footer-inner .footer-links .link-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  footer .footer-inner .footer-links a {
    font-size: 2vw;
    letter-spacing: 0.5vw;
  }
}
.gold {
  color: #c69f31;
}

.coral {
  color: #c6764c;
}

.marine {
  color: #386163;
}

.deepblue {
  color: #414d48;
}

.page-gradient {
  background: #f8f8f8;
  background: -webkit-linear-gradient(top, #f8f8f8, #e3e3e3);
  background: linear-gradient(to bottom, #f8f8f8, #e3e3e3);
}

.footer-gradient {
  background: #35414c;
  background: -webkit-linear-gradient(top, #35414c, #232d33);
  background: linear-gradient(to bottom, #35414c, #232d33);
}

@media only screen and (max-width: 832px), (max-device-width: 416px) {
  .loader p.sub-title {
    font-size: 4vw;
  }

  .content-container {
    width: 100%;
    overflow-x: hidden;
  }

  .header-logo {
    width: 24vw;
    padding-top: 12vw;
    margin-bottom: 18vw;
  }

  .large-title {
    font-size: 9.2vw;
    line-height: 1.2;
    width: 80%;
  }

  .video-container {
    width: 150vw;
    margin-left: -25vw;
    padding-bottom: 90vw;
    margin-top: -10vw;
  }

  video {
    width: 250vw;
    transform: translate3d(-25%, -25%, 0);
  }

  .medium-title {
    width: 85%;
    font-size: 7vw;
    line-height: 1.4;
  }
  .medium-title .sub-title {
    font-size: 4vw;
    margin-bottom: 1vw;
  }
  .medium-title div {
    margin-top: 4vw;
    margin-bottom: 16vw;
  }

  .sub-title {
    width: 85%;
    margin: auto;
    font-size: 3vw;
    letter-spacing: 0.75vw;
    line-height: 2;
  }

  .accent-copy {
    font-size: 4vw;
    line-height: 1.6;
  }

  .body-copy {
    font-size: 4.8vw;
    line-height: 1.5;
  }

  .accent-copy {
    font-size: 5.6vw;
    line-height: 1.6;
  }

  .mission {
    margin-top: 12vw;
    margin-bottom: 50vw;
    width: 80%;
  }

  .we-are-thoughtful {
    margin-bottom: 40vw;
  }

  .where-we-invest {
    margin-bottom: 8vw;
  }

  .card-gallery {
    flex-direction: column;
    width: 80%;
    margin-bottom: 32vw;
  }
  .card-gallery .card {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    flex: none;
  }
  .card-gallery .card:first-of-type {
    margin-left: 0px;
  }
  .card-gallery .card:not(:first-of-type) {
    margin-left: 0px;
  }
  .card-gallery .card:hover {
    transform: translate3d(0, 0, 0);
  }
  .card-gallery .card .card-title {
    font-size: 5.5vw;
    min-height: 7vw;
    overflow: visible;
    padding-bottom: 0;
  }
  .card-gallery .card .card-bottom {
    padding: 7vw 7vw 24vw 7vw;
  }
  .card-gallery .card .card-content {
    font-size: 4vw;
    line-height: 1.6;
  }
  .card-gallery .card .card-content ul {
    padding-inline-start: 3.5vw;
  }
  .card-gallery .card button {
    font-size: 3.5vw;
  }
  .card-gallery .card button .button-text-wrap {
    margin: 0 7vw 7vw 0;
  }

  .how-table {
    width: 85%;
    margin-bottom: 24vw;
  }
  .how-table .how-table-row {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 5vw;
    border-top: 1px solid #dadcdf;
  }
  .how-table .how-table-row .tablet-column {
    flex-direction: column;
  }
  .how-table .how-table-row .number {
    font-size: 5vw;
    line-height: 1;
    margin-bottom: 5vw;
    font-weight: 800;
    display: flex;
    align-items: center;
  }
  .how-table .how-table-row .number img {
    width: 24px;
    height: 24px;
    margin-right: 3vw;
    margin-bottom: 2px;
  }
  .how-table .how-table-row .question {
    font-size: 8vw;
    margin: 2vw 0 2vw 0;
    line-height: 1.25;
    max-width: none;
  }
  .how-table .how-table-row .answer {
    margin-left: 0;
    max-width: none;
    font-size: 5vw;
  }

  .where-weve-invested {
    margin-bottom: 24vw;
  }

  .cutout-gallery {
    width: 80%;
    margin-bottom: 40vw;
  }
  .cutout-gallery .cutout-inner {
    width: 240vw;
    flex-direction: row;
  }
  .cutout-gallery .mobile-controls {
    display: flex;
    z-index: 10;
    width: 80%;
    margin: 4vw auto 0 auto;
    justify-content: space-around;
    align-items: center;
    font-size: 5vw;
    letter-spacing: 1vw;
    font-weight: 600;
  }
  .cutout-gallery .mobile-controls .previous-button,
.cutout-gallery .mobile-controls .next-button {
    outline: none;
    border: none;
    font-size: 16vw;
    cursor: pointer;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    color: #414d48;
    padding: 1vw 1vw 1vw 1vw;
  }
  .cutout-gallery .mobile-controls .previous-button:active,
.cutout-gallery .mobile-controls .next-button:active {
    outline: none;
  }
  .cutout-gallery .mobile-controls .previous-button {
    margin-right: 16px;
  }
  .cutout-gallery .mobile-controls .next-button {
    margin-left: 16px;
  }
  .cutout-gallery .row {
    flex-direction: column;
    width: 80vw;
  }
  .cutout-gallery .row:nth-of-type(2) {
    transform: translate(100vw, 0);
    margin-left: -80vw;
  }
  .cutout-gallery .row:nth-of-type(3) {
    transform: translate(-100vw, 0);
    margin-left: -80vw;
  }
  .cutout-gallery .row:not(:first-of-type) {
    margin-top: 0;
  }
  .cutout-gallery .row img {
    flex: none;
    width: 80%;
  }
  .cutout-gallery .row .text-wrap .cutout-header {
    font-size: 7vw;
    line-height: 1.3;
    margin-top: 8vw;
    margin-bottom: 6vw;
  }
  .cutout-gallery .water,
.cutout-gallery .sunset,
.cutout-gallery .farmland {
    margin-right: 0;
    margin-left: 0;
    width: 90%;
  }

  .our-team {
    max-width: 60%;
    margin-bottom: 40vw;
  }

  .team-container {
    width: 78%;
    margin-bottom: 24vw;
  }
  .team-container .team-member {
    flex-direction: column;
    margin-top: 6vw;
  }
  .team-container .team-member .headshot {
    margin: auto;
    width: 100%;
  }
  .team-container .team-member .headshot img {
    width: 100%;
    margin-bottom: 6vw;
  }
  .team-container .team-member .headshot .links {
    justify-content: center;
    margin-bottom: 2vw;
  }
  .team-container .team-member .headshot .links a {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 4.5vw;
    letter-spacing: 1vw;
  }
  .team-container .team-member .headshot .name {
    font-size: 7vw;
  }
  .team-container .team-member .about {
    margin: 0;
  }
  .team-container .team-member .about .body-copy {
    font-size: 4.8vw;
    margin-bottom: 0;
  }
  .team-container .team-member .about .about-bottom {
    flex-direction: column;
    margin-top: 4vw;
  }
  .team-container .team-member .about .about-bottom .sub-title {
    font-size: 5vw;
    font-weight: 600;
  }
  .team-container .team-member .about .about-bottom .detail,
.team-container .team-member .about .about-bottom .experience {
    font-size: 4.5vw;
    margin-bottom: 5vw;
    margin-left: 0;
  }
  .team-container .team-member .about .sub-title {
    width: 100%;
  }

  .bottom-links {
    flex-direction: column;
    width: 85%;
    margin-bottom: 24vw;
  }
  .bottom-links div.link-wrap {
    max-width: 100%;
    width: 100%;
  }
  .bottom-links div.link-wrap:first-of-type {
    margin-bottom: 8vw;
  }
  .bottom-links .link-text {
    font-size: 7vw;
    max-width: 70%;
    margin-left: 8vw;
    margin-top: 10vw;
  }
  .bottom-links .link-arrow {
    font-size: 8vw;
    max-width: 48px;
  }

  #mce-EMAIL {
    display: block;
  }

  .clear {
    margin-top: 6vw;
  }

  .email-list {
    margin-bottom: 32vw;
  }
  .email-list .large-title {
    margin-bottom: 8vw;
  }
  .email-list .sub-title {
    line-height: 0;
  }
  .email-list input {
    max-width: 80%;
    width: 80%;
    font-size: 4vw;
  }
  .email-list button {
    margin-top: 6vw;
    width: 40%;
    font-size: 4vw;
  }

  footer {
    height: auto;
    padding: 20vw 16px;
  }
  footer .footer-inner {
    flex-direction: column;
    width: 85%;
  }
  footer .footer-inner i.body-copy {
    font-size: 5vw;
  }
  footer .footer-inner .footer-logo {
    width: 40%;
    margin: auto;
  }
  footer .footer-inner .footer-links {
    flex-direction: column;
  }
  footer .footer-inner .footer-links .office {
    width: 100%;
  }
  footer .footer-inner .footer-links div {
    display: block;
    margin-bottom: 40px;
  }
  footer .footer-inner .footer-links .sub-title {
    font-size: 6vw;
    margin-left: 0;
    margin-bottom: 3vw;
  }
  footer .footer-inner .footer-links .follow-us {
    width: 100%;
    margin-right: 0;
    justify-content: space-around;
  }
  footer .footer-inner .footer-links .link-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  footer .footer-inner .footer-links a {
    font-size: 5vw;
    letter-spacing: 1vw;
  }
}
/*# sourceMappingURL=index.417e31bd.css.map */

@import "./components/reset.scss";
@import "./components/variables.scss";

// Source Sans Pro - styles & weights: normal 400 600 / italic 400
// Source Serif Pro - styles & weights: normal 300 400 600 / italic 600

body {
  overflow-x: hidden;
}

.clipping-paths {
  width: 0;
  height: 0;
  position: absolute;
}

.loader {
  position: fixed;
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  background-color: $brand-color-lightest;

  z-index: 100;

  img,
  p {
    text-align: center;
  }

  .sub-title {
    color: $brand-color-darkest;
    font-size: 1.5vw;
    line-height: 1;
    margin: 0 auto;
    letter-spacing: 1.2px;
  }

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 10px 3px 0;
    background: $brand-color-darkest;
    border-radius: 50%;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    animation: wave 2s infinite ease-in-out;
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      background: lighten($brand-color-darkest, 10%);
      transform: translateY(0);
      -moz-transform: translateY(0);
    }

    20% {
      background: lighten($brand-color-darkest, 20%);
      transform: translateY(20px);
    }

    40% {
      background: lighten($brand-color-darkest, 40%);
      transform: translateY(-10px);
    }
  }

  span:nth-child(1) {
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
  }

  span:nth-child(2) {
    animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
  }

  span:nth-child(4) {
    animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
  }

  span:nth-child(5) {
    animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
  }
}

p {
  padding-bottom: 1vw;
}

.main {
  display: flex;
  flex-direction: column;
}

.content-container {
  max-width: 1850px;

  margin: 0 auto;
}

/* unvisited link */
a:link {
  color: $link-color;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 45px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.25vw;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: $link-visited;
}

/* mouse over link */
a:hover {
  text-decoration: underline;
}

/* selected link */
a:active {
  text-decoration: underline;
  color: $link-active;
}

a:focus {
  text-decoration: underline;
  outline: none;
}

.header-logo {
  width: 7.25vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4vw;
  margin-bottom: 4.5vw;
}

.we-act {
  position: relative;
  z-index: 10;
  text-align: center;
}

.video-container {
  margin: 0 auto;
  height: 0; // these two lines are css aspect ratio hack
  padding-bottom: 60vw; // these two lines are css aspect ratio hack
  width: 100vw;
  margin-top: -5vw;
  clip-path: url(#clip-hero-video);
  margin-bottom: 4.4vw;
  overflow: hidden;
}

video {
  min-width: 0px;
  width: 150vw;
  overflow: hidden;
  transform: translate3d(-25%, -25%, 0);
}

.video-insert {

  &::-webkit-media-controls {
    display: none !important;
  }
}

.mission {
  width: 41%;
  margin: auto;
  text-align: center;
  margin-bottom: 16vw;
}

.center-copy {
  text-align: center;
}

.descriptor-copy {
  color: $brand-color-dark;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 45px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
}

// Style Guide "Lead-In"
.sub-title {
  color: $brand-color-mid;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 3.5;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.25vw;
}

.link-copy {
  color: $link-color;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 4px;
}

.body-copy {
  color: $brand-color-dark;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 2vw;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.24px;
}

.accent-copy {
  color: $brand-color-dark;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  // font-size: calc(12px + 1.2vw);
  font-size: 1.6vw;
  line-height: 1.6;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.3px;
}

// Style Guide "Subhead"
.medium-title {
  width: 74%;
  margin: 0 auto;
  color: $brand-color-middark;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 2.25vw;
  line-height: 1.25;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.42px;
}

.number {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.25vw;
  // line-height: 66px;
  font-family: "Source Serif Pro", serif;
  letter-spacing: 0px;
}

.question {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 2.25vw;
  // line-height: 55px;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.44px;
}

// Style Guide "Headline Copy"
.large-title {
  width: 65.65%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  color: $brand-color-middark;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 4vw;
  line-height: 1.3;
  font-family: "Source Serif Pro", serif;
  letter-spacing: -0.5px;
  text-align: center;
}

.we-are-thoughtful {
  margin-bottom: 16vw;
}

.where-we-invest {
  margin-bottom: 5vw;
}

.card-gallery {
  width: 79.79%;
  margin: auto;

  display: flex;
  justify-content: space-between;

  .card {
    position: relative;

    display: flex;
    flex-direction: column;

    flex: 1;

    transform: translate3d(0, 0px, 0);

    border-radius: 16px;
    box-shadow: 0px 0px 10px #00000029;
    overflow: hidden;

    background-color: $brand-color-lightest;

    transition: transform 0.4s;
    transition-delay: .1s;
    &:hover,
    &:focus-within {
      transform: translate3d(0, -10px, 0);
    }

    &:focus-within {
      box-shadow: 0px 0px 10px #00000055;
    } 

    &:not(:first-of-type) {
      margin-left: 20px;
    }

    .card-img-wrap {
      height: 0;

      &:after {
        content: "";
        padding-bottom: 80%;
      }
    }

    .card-bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 81.6%;
      // padding: 2vw 1vw;
      padding: 2vw 1vw 4vw 1.5vw;
      background-color: $brand-color-lightest;
      color: $accent-color-deepblue;
    }

    .card-title {
      font-family: "Source Serif Pro", serif;
      font-size: 1.5vw;
      z-index: 10;
    }

    .card-content {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1vw;

      opacity: 0;
      padding-top: 0.5vw;
      // height: 70px;
      height: 0px;

      ul {
        padding-inline-start: 1.25vw;
      }
    }

    button {
      position: absolute;
      display: flex;

      width: 100%;
      height: 100%;

      bottom: 0px;
      right: 0px;
      left: 0px;
      top: 0px;

      z-index: 11;

      background: transparent;
      border: none;

      font-family: "Source Sans Pro", sans-serif;
      color: $link-color;
      font-weight: 600;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      cursor: pointer;

      appearance: none;
      -webkit-tap-highlight-color: transparent;
      user-select: none;

      justify-content: flex-end;
      align-items: flex-end;

      &:focus  {
        outline: none;
      }

      &:active {
        color: lighten($link-color, 15%);
        appearance: none;
        outline: none;
      }

      &:visited {
        outline: none;
      }

      // inner text wrap
      .button-text-wrap {
        margin: 0 16px 16px 0;
        overflow: hidden;
      }
    }

    .bottom-bar {
      position: absolute;
      height: 50px;
      bottom: 0;
      width: 100%;

      background-color: $brand-color-lightest;
    }
  }

  margin-bottom: 15vw;
}

.how-we-invest {
  margin-bottom: 5vw;
}

.how-table {
  display: flex;
  flex-direction: column;

  width: 79.5%;
  margin: auto;
  margin-bottom: 8vw;

  .how-table-row {
    display: flex;

    align-items: top;

    padding-top: 25px;
    margin-bottom: 49px;

    border-top: 1px solid $brand-color-light;

    .tablet-column {
      display: flex;
      flex-direction: row;
      flex: 4;
    }

    .number {
      flex: 1;
      font-weight: 600;

      img {
        width: 16px;
        margin-right: 8px;
        margin-bottom: -2px;
      }
    }

    .question {
      flex: 2;
      justify-self: left;
      margin-right: 6vw;
      max-width: 420px;
    }

    .answer {
      flex: 3;
      margin-left: 6vw;
    }
  }
}

.where-weve-invested {
  margin-bottom: 6vw;
  width: 55%;
}

.cutout-gallery {
  width: 63.5%;
  margin: 0 auto;
  margin-bottom: 16vw;

  .cutout-inner {
    display: flex;
    flex-direction: column;
  }

  .mobile-controls {
    display: none;
    color: $accent-color-deepblue;
    font-family: "Source Sans Pro", sans-serif;

    button.previous-button img {
      transform: scaleX(-1);
    }

    button {
      padding: 1vw .5vw 1vw .5vw;
      line-height: 0.55;
      outline: 0;
      display: flex;

    }

    

    button:focus {      
      outline: $focus-outline; 
    }

    button:active{
      outline: 0;
    }

    
  }

  .sunset {
    width: 50%;
    clip-path: url(#clip-sunset);
    margin-right: 5vw;
    margin-bottom: 6vw;
  }

  .water {
    width: 50%;
    clip-path: url(#clip-water);
    margin-left: 5vw;
    margin-bottom: 6vw;
  }

  .farmland {
    width: 50%;
    clip-path: url(#clip-farmland);
    margin-right: 5vw;
    margin-bottom: 6vw;
  }

  .row {
    display: flex;
    align-items: center;

    &:not(:first-of-type) {
      // margin-top: -6%;
    }

    img {
      flex: 1;
    }

    .text-wrap {
      flex: 1;
      margin-top: -6vw;

      i {
        font-weight: 600;
      }

      .cutout-header {
        font-family: "Source Serif Pro", serif;
        font-size: 1.55vw;
        line-height: 1.5;
        color: $brand-color-dark;
        margin-bottom: 1.25vw;
      }
    }
  }

  .flipped {
    flex-direction: row-reverse;
  }
}

.our-team {
  margin-bottom: 16vw;
  width: 50%;
}

.body-copy {
  font-size: 1.25vw;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 0;
}

.team-container {
  display: flex;
  flex-direction: column;

  max-width: 1050px;
  width: 55%;

  margin: 0 auto 10vw auto;

  .team-member {
    display: flex;
    align-items: center;
    margin-bottom: 90px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .headshot {
      flex: 1.5;
      align-self: flex-start;

      img {
        display: block;
        width: 77%;
        margin: auto;
        margin-bottom: 1vw;
      }

      .name {
        text-align: center;
        color: $accent-color-deepblue;
        font-family: "Source Serif Pro", serif;
        font-weight: 600;
        font-size: 1.5vw;
      }

      .links {
        display: flex;
        justify-content: center;

        a {
          letter-spacing: 3px;
          padding-left: 1.15vw;
          padding-right: 1.15vw;
          margin-top: 1vw;
          line-height: 1.75;
          // &:focus {
          //   outline: $focus-outline; 
          // }

          &:active, &:visited {
            outline: none;
          }
        }
      }
    }

    .about {
      flex: 2;

      .body-copy {
        margin-bottom: 0vw;
      }

      .about-bottom {
        display: flex;
        justify-content: flex-start;

        .sub-title {
          line-height: 2;
        }

        .detail,
        .experience {
          font-family: "Source Sans Pro", sans-serif;
          color: $accent-color-deepblue;
          font-size: 1vw;
        }

        .detail {
            margin-left: 2vw;
          }
      }
    }
  }
}

.bottom-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 65.625%;
  margin: auto;
  margin-bottom: 9vw;

  div {
    z-index: 1;
  }

  a {
    display: block;
  }

  div.link-wrap {    
    // max-width: calc(50% - 20px);
    width: calc(50% - 20px);
    border-radius: 16px;
    box-shadow: 0px 0px 10px #0000003e;

    &:focus-within {
      box-shadow: 0px 0px 10px #00000055;
    } 

    a {
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      font-family: "Source Serif Pro", serif;
      letter-spacing: 0vw;
      font-size: 2.25vw;
      line-height: 1.5;
      width: 100%;
      outline: none;  
      &:focus img {
        filter: brightness(40%);
      }

      
    }
  }

  div.link-inner {
    position: relative;
    height: 0px;
    padding-top: 66.667%;
  }

  .link-image-wrap {
    position: absolute;
    height: 0;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    border-radius: 16px;
    z-index: 0;

    img {
      border-radius: 16px;
      transition: filter 0.2s;
      filter: brightness(70%);

      &:hover {
        filter: brightness(40%);
      }
    }
  }

  .link-text {
    position: absolute;
    top: 0px;
    max-width: 80%;
    margin-left: 3vw;
    margin-top: 4vw;
    color: $brand-color-lightest;
    pointer-events: none;
  }

  .link-arrow {
    position: absolute;
    display: flex;
    bottom: 0px;
    right: 0px;
    max-width: 64px;
    margin-right: 32px;
    margin-bottom: 24px;
    color: $brand-color-lightest;
    font-family: "Source Sans Pro", sans-serif;
    pointer-events: none;
  }

  .link-text:hover ~ .link-image-wrap img,
  .link-arrow:hover ~ .link-image-wrap img {
    filter: brightness(70%);
  }
}

#mc_embed_signup {
  width: 100%;
}

#mce-EMAIL {
  display: block;
}

.clear {
  margin-top: -2.5vw;
}

#mc-embedded-subscribe {
  display: block;
  width: auto;
  padding: 0px 24px;
  inset: none;
  border: none;
  background: #707e8e;
  color: #f8f8f8;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: $brand-color-lightest auto 1px;
  }

  &:hover {
    background: $footer-start-color;
  }

  &:active {
    background: $brand-color-darkest;
    outline: none;
  }
}

.email-list {
  display: flex;
  flex-direction: column;

  margin-bottom: 6vw;

  .sub-title {
    line-height: 1;
  }

  * {
    margin: 0 auto;
    margin-bottom: 1.25vw;
  }

  input {
    width: 25%;
    height: 50px;
    padding: 0;
    outline: 0;
    background: $page-end-color;
    border: 1px solid $brand-color-dark;
    border-radius: 8px;
    text-align: center;

    &:focus {
      &::-webkit-input-placeholder {
        text-align: center;
        opacity: 0;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
        opacity: 0;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
        opacity: 0;
      }

      &:-ms-input-placeholder {
        text-align: center;
        opacity: 0;
      }
    }

    &::-webkit-input-placeholder {
      text-align: center;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
    }

    &:-ms-input-placeholder {
      text-align: center;
    }
  }

  button, input.button {
    width: auto;
    padding: 4px 24px;
    inset: none;
    border: none;
    background: $brand-color-mid;
    color: $brand-color-lightest;
    border-radius: 8px;
    outline: none;

    cursor: pointer;
    -webkit-appearance: none;

    &:focus {
      outline: $brand-color-lightest auto 1px;
    }

    &:hover {
      background: $footer-start-color;
      outline: none;
    }

    &:active {
      background: $brand-color-darkest;
      outline: none;
    }
  }
}

footer {
  display: flex;
  height: 22vw;
  background-color: $brand-color-darkest;

  .footer-inner {
    display: flex;

    width: 66.6265%;
    max-width: 1260px;
    justify-content: space-between;

    margin: auto;

    i.body-copy {
      color: $brand-color-lightest;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1vw;
      font-style: italic;
    }

    .footer-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 2;

      a {
        line-height: 1.3;
        margin-left: -.5vw;
        margin-top: -.5vw;
        padding: .5vw;
        color: $brand-color-lightest;
      }

      a:visited {
        color: $brand-color-light;
        outline: none;
      }

      
      // a:focus {
      //   outline: $focus-outline-light;
      // }

      a:active {
        color: $brand-color-mid;
        outline: none;
      }

       

     

      > div {
        display: flex;
        flex-direction: column;
        line-height: 1;
        color: $brand-color-lightest;
        margin-bottom: 2vw;
      }

      .link-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        a:nth-of-type( 2 ) {
          margin-left: 1vw;
        }
      }

      .link-title {
        color: $brand-color-midlight;
      }

      .office {
        min-width: 30%;
        // width: 300px;
      }

      .follow-us {
        margin-bottom: 0vw;
        flex: 1;
        margin-right: 6vw;
        min-width: 192px;
        // a
        // {
        //   overflow: visible;
        // }
      }

      .reach-out {
        flex: 1;
        min-width: 100px;
        a {
          width: max( 45%, 3vw );
        }
      }
    }

    .footer-logo {
      width: 80px;

      align-self: flex-start;
    }
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  * {
    /* Very short durations means JavaScript that relies on events still works */
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

//
//
// TABLET
//
//

// moved into styles/components/tablet.scss
// gets imported in index.js

//
//
// MOBILE
//
//

// moved into styles/components/mobile.scss
// gets imported in index.js

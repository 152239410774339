@import "./variables.scss";

@media only screen and (max-width: 1080px) and (min-width: 833px),
  (max-device-width: 544px) and (min-device-width: 417px) {
  .loader p.sub-title {
    font-size: 2vw;
  }

  .content-container {
    width: 100%;
    overflow-x: hidden;
  }

  .header-logo {
    width: 12vw;
    padding-top: 6vw;
    margin-bottom: 9vw;
  }

  .large-title {
    font-size: 5.25vw;
    line-height: 1.4;
    width: 85%;
    margin-bottom: 2vw;
  }

  .video-container {
    // display: inline-block;
    width: 150vw;
    margin-left: -25vw;
    padding-bottom: 90vw;
    margin-top: -8vw;
    margin-bottom: 12vw;
  }

  video {
    width: 250vw;
  }

  .we-act {
    width: 55%;
    margin-bottom: -3.25vw;
  }

  .medium-title {
    width: 80%;
    font-size: 4vw;

    line-height: 1.3;
  }

  .sub-title {
    width: 85%;
    margin: auto;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 2vw;
    letter-spacing: 3px;
  }

  .accent-copy {
    font-size: 3vw;
    line-height: 1.6;
  }

  .body-copy {
    font-size: min(24px, 4vw);
    line-height: 1.6;
  }

  .mission {
    width: 62%;
    margin-bottom: 30vw;
  }

  .we-are-thoughtful {
    margin-bottom: 30vw;
  }

  .card-gallery {
    flex-direction: row;
    flex-wrap: wrap;

    width: 80%;

    .card {
      flex: 1 0 calc(50% - 50px);

      margin: 20px 15px 20px 15px;

      &:hover {
        transform: translate3d(0,0,0);
      }

      .card-title {
        font-size: 2.75vw;
        min-height: 7vw;
        overflow: visible;
        padding-bottom: 0;
      }

      .card-content {
        font-size: 2vw;
        line-height: 1.4;
      }

      .card-bottom {
        padding: 4vw 3.5vw 8vw 3.5vw;
      }

      button {
        font-size: 1.75vw;

        .button-text-wrap {
          margin: 0 5vw 3vw 0;
        }
      }
    }
  }

  .how-we-invest {
    margin-bottom: 8vw;
    width: 60%;
  }

  .how-table {

    width: 85%;

    .how-table-row {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;

      padding-top: 25px;


      .tablet-column {
        flex-direction: column;
        margin-right: 20px;
      }

      .number {
        font-size: 2vw;
        line-height: 1;

        img {
          width: 24px;
          margin-bottom: -4px;
        }
      }

      .question {
        font-size: 4vw;
        margin: 10px 0;
        line-height: 1.5;
      }

      .answer {
        padding-top: 4.5vw;
        align-self: baseline;
        font-size: 2.15vw;
        margin-left: 0;
      }
    }
  }

  .where-weve-invested {
    margin-bottom: 8vw;
    width: 80%;
  }

  .cutout-gallery {
    width: 70%;
    margin-bottom: 80px;

    .cutout-inner {
      width: 210vw;
      flex-direction: row;
    }

    .mobile-controls {
      display: flex;

      z-index: 10;

      width: 50%;
      margin: auto;

      justify-content: space-around;
      align-items: center;

      font-size: 2vw;
      letter-spacing: 0.5vw;
      font-weight: 600;

      .previous-button,
      .next-button {
        outline: none;
        border: none;
        font-size: 6vw;
        cursor: pointer;
        background: transparent;
        border: none;
        -webkit-appearance: none;
        color: $accent-color-deepblue;

        &:active {
          outline: none;
        }
      }

      .previous-button {
        margin-right: 16px;
      }

      .next-button {
        margin-left: 16px;
      }
    }

    .row {
      flex-direction: column;
      width: 70vw;
      margin-bottom: 3vw;

      &:nth-of-type(2) {
        transform: translate(100vw, 0);
        margin-left: -70vw;
      }

      &:nth-of-type(3) {
        transform: translate(-100vw, 0);
        margin-left: -70vw;
      }

      &:not(:first-of-type) {
        margin-top: 0;
      }

      .text-wrap {
        width: 90%;

        .body-copy {
          font-size: 2.1vw;
        }

        .cutout-header {
          font-size: 3vw;
          margin-top: 8vw;
        }
      }
    }

    .water,
    .sunset,
    .farmland {
      margin-right: 0;
      margin-left: 0;
      width: 80%;
    }
  }

  .our-team {
    margin-top: 25vw;
    margin-bottom: 20vw;
  }

  .team-container {
    width: 66%;

    .team-member {
      flex-direction: column;

      .headshot {
        margin: auto;
        width: 60%;

        img {
          width: 100%;
          margin-bottom: 2vw;
        }

        .links a {
          font-size: 2vw;
          letter-spacing: 0.4vw;
          
        }

        .name {
          font-size: 3vw;
        }
      }

      .about {
        margin: 0;
        width: 95%;

        .body-copy {
          line-height: 1.5;
        }

        .about-bottom {
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 4vw;

          .sub-title {
            font-size: 2vw;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 1vw;
          }

          .detail,
          .experience {
            font-size: 2vw;
            margin-bottom: 5vw;
          }

          .detail {
            margin-left: 8vw;
          }
        }

        .sub-title {
          width: 100%;
        }
      }
    }
  }

  .bottom-links {
    width: 80%;
    margin-bottom: 15vw;

    .link-text {
      font-size: 2.75vw;
      width: 60%;
    }

    .link-arrow {
      font-size: 6vw;
    }
  }

  .email-list {
    margin-bottom: 10vw;

    input {
      max-width: 40%;
      width: 40%;
      margin-bottom: 1vw;
    }

    button {
      margin-top: 1vw;
    }
  }

  footer {
    height: auto;
    padding: 12vw 2vw 6vw 2vw;

    .footer-inner {
      // flex-direction: column;
      width: 75%;

      i.body-copy {
        font-size: 2vw;
      }

      .footer-logo {
        width: 12vw;
        margin: 10vw 5vw 0vw auto;
      }

      .footer-links {
        flex-direction: column;

        div {
          display: block;
          margin-bottom: 40px;
        }

        .sub-title {
          font-size: 2vw;
          margin-left: 0;
          margin-bottom: 2.5vw;
          letter-spacing: 0.6vw;
        }

        .follow-us {
          width: 100%;
          max-width: 350px;
          margin-right: 0;
          justify-content: space-around;
        }

        .link-wrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        a {
          font-size: 2vw;
          letter-spacing: 0.5vw;
        }
      }
    }
  }
}

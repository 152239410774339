$brand-color-darkest: #1b2428;
$brand-color-dark: #35414c;
$brand-color-middark: #4e5860;
$brand-color-mid: #707e8e;
$brand-color-midlight: #95a0a9;
$brand-color-light: #dadcdf;
$brand-color-lightest: #f8f8f8;

$accent-color-gold: #c69f31;
$accent-color-coral: #c6764c;
$accent-color-marine: #386163;
$accent-color-deepblue: #414d48;

$link-color: #1b71c9; //need colors from Matt for visited, hover, active states
$link-active: #0B539D;
$link-visited: #4B84D1;

$link-active-outline: none;

$focus-outline: 2px dotted $brand-color-mid;
$focus-outline-light: 2px dotted $brand-color-lightest;

$page-start-color: #f8f8f8; //same as brand-color-lightest
$page-end-color: #e3e3e3; // recommend replacing with brand-color-light

$footer-start-color: #35414c; // recommend replacing with brand-color-dark
$footer-end-color: #232d33; //recommend replacing with brand-color-darkest

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == 'vertical' {
      background: -webkit-linear-gradient(top, $start-color, $end-color);
      background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
      background: -webkit-linear-gradient(left, $start-color, $end-color);
      background: linear-gradient(to right, $start-color, $end-color);
    } @else {
      background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
      background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

.gold {
  color: $accent-color-gold;
}

.coral {
  color: $accent-color-coral;
}

.marine {
  color: $accent-color-marine;
}

.deepblue {
  color: $accent-color-deepblue;
}

.page-gradient {
  @include gradient($page-start-color, $page-end-color, vertical);
}

.footer-gradient {
  @include gradient($footer-start-color, $footer-end-color, vertical);
}
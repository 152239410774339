body,
html {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
